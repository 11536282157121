import { combineReducers } from 'redux'
import { authenticationReducer } from './authentication/reducer'
import { localeReducer } from './locale/reducer'
import { tournamentReducer } from './tournament/reducer'
import { tournamentPlayersReducer } from './tournamentPlayers/reducer'
import { tournamentStartlistsReducer } from './tournamentStartlists/reducer'
import { tournamentLeaderboardReducer } from './tournamentLeaderboard/reducer'
import { tournamentContestLeaderboardReducer } from './tournamentContestLeaderboard/reducer'
import { registrationsReducer } from './registrations/reducer'
import { clubsAndCountriesReducer } from './clubsAndCountries/reducer'
import { tourAndRankingReducer } from './tourAndRanking/reducer'
import { calendarReducer } from './calendar/reducer'
import { paymentReducer } from './payments/reducer'
import { pageReducer } from './pages/reducer'
import { api } from './api/baseApi'
import signupReducer from './signup/signupSlice'

const indexReducer = () =>
  combineReducers({
    localeReducer,
    authenticationReducer,
    tournamentReducer,
    tournamentPlayersReducer,
    registrationsReducer,
    tournamentStartlistsReducer,
    tournamentLeaderboardReducer,
    tournamentContestLeaderboardReducer,
    clubsAndCountriesReducer,
    tourAndRankingReducer,
    calendarReducer,
    paymentReducer,
    pageReducer,
    signupReducer,
    [api.reducerPath]: api.reducer,
  })

export default indexReducer
