import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_ROOT } from '../../config'
import { RootState } from '..'

export enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export enum CacheTag {
  TOURNAMENT_SITE = 'TOURNAMENT_SITE',
}

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: API_ROOT,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).authenticationReducer?.user.authToken
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  tagTypes: Object.values(CacheTag),
  endpoints: () => ({}),
})
