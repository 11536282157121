/* eslint-disable max-len */

export const WarningIcon = ({
  style = {},
  width = '100%',
  className = '',
  height = '100%',
  viewBox = '0 0 150 150',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
    className={className}
    style={style}
    fill="none"
  >
    <g clipPath="url(#clip0_4545_7391)">
      <circle
        cx="74.7999"
        cy="75.4199"
        r="29.7363"
        transform="rotate(128.544 74.7999 75.4199)"
        stroke="url(#paint0_linear_4545_7391)"
        strokeOpacity="0.15"
        strokeWidth="17"
      />
      <circle
        cx="75.0011"
        cy="75"
        r="46.5457"
        transform="rotate(-150.542 75.0011 75)"
        stroke="url(#paint1_linear_4545_7391)"
        strokeOpacity="0.13"
        strokeWidth="17"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.1522 71.643C65.3774 58.8325 68.9901 52.4272 74.711 52.4272C80.4319 52.4272 84.0445 58.8325 91.2697 71.643L92.1701 73.2394C98.1742 83.8848 101.176 89.2076 98.463 93.0995C95.7498 96.9914 89.037 96.9914 75.6113 96.9914H73.8106C60.385 96.9914 53.6722 96.9914 50.9589 93.0995C48.2457 89.2076 51.2477 83.8848 57.2519 73.2394L58.1522 71.643ZM74.711 62.9493C75.7365 62.9493 76.5678 63.7807 76.5678 64.8062V77.1851C76.5678 78.2106 75.7365 79.0419 74.711 79.0419C73.6855 79.0419 72.8541 78.2106 72.8541 77.1851V64.8062C72.8541 63.7807 73.6855 62.9493 74.711 62.9493ZM74.711 87.0882C76.0783 87.0882 77.1868 85.9798 77.1868 84.6125C77.1868 83.2451 76.0783 82.1367 74.711 82.1367C73.3436 82.1367 72.2352 83.2451 72.2352 84.6125C72.2352 85.9798 73.3436 87.0882 74.711 87.0882Z"
        fill="#098F50"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_4545_7391"
        x1="74.7999"
        y1="37.1836"
        x2="74.7999"
        y2="113.656"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1AA85D" />
        <stop offset="1" stopColor="#1AA85D" stopOpacity="0.4" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4545_7391"
        x1="116.511"
        y1="22.2102"
        x2="75.0011"
        y2="130.046"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1AA85D" stopOpacity="0" />
        <stop offset="1" stopColor="#1AA85D" />
      </linearGradient>
      <clipPath id="clip0_4545_7391">
        <rect width="150" height="150" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
