import axios from 'axios'
import { APIRoute } from '../../../config'
import { JoinTournamentAction, TeamJoinTournamentAction } from '../actions'

export function* playerJoinTournamentFlow({
  tournamentId,
  authToken,
  hcp,
  customQuestions,
  team,
  teamName,
  gender,
  teebox,
  divisionId,
  lang,
  onComplete,
}: JoinTournamentAction) {
  try {
    const res = yield axios.post(
      APIRoute.POST_TOURNAMENT_PLAYER(tournamentId),
      {
        hcp,
        customQuestions,
        team,
        teamName,
        gender,
        teebox,
        divisionId,
        lang,
      },
      { headers: { Authorization: `Bearer ${authToken}` } },
    )

    onComplete(true, res.data.reserveList, res.data.error)
  } catch (error) {
    onComplete(false, false, error)
  }
}

export function* teamJoinTournamentFlow({
  tournamentId,
  authToken,
  onComplete,
  team,
  teamName,
  divisionId,
  lang,
}: TeamJoinTournamentAction) {
  try {
    const res = yield axios.post(
      APIRoute.POST_TOURNAMENT_TEAM(tournamentId),
      {
        team,
        teamName,
        divisionId,
        lang,
      },
      { headers: { Authorization: `Bearer ${authToken}` } },
    )

    onComplete(true, res.data.reserveList, res.data.error)
  } catch (error) {
    onComplete(false, false, error)
  }
}
