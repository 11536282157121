/* eslint-disable max-len */
export const GA_TRACKING_CODE = process.env.REACT_APP_GA_TRACKING_CODE

export const formAPIRoot = () => {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_API_URL || 'http://localhost:9981/'
  } else if (process.env.REACT_APP_ENV === 'sandbox') {
    return 'https://tournament-api.sandbox.golfgamebook.com/'
  } else if (process.env.REACT_APP_ENV === 'staging') {
    return 'https://tournament-api.staging.golfgamebook.com/'
  } else if (process.env.REACT_APP_ENV === 'rc') {
    return 'https://tournament-api.rc.golfgamebook.com/'
  } else if (process.env.REACT_APP_ENV === 'production') {
    return 'https://tournament-api.golfgamebook.com/'
  } else {
    return 'https://tournament-api.sandbox.golfgamebook.com/'
  }
}

export const API_ROOT: string = formAPIRoot()
export const APIRoute = {
  // Login
  POST_CORE_LOGIN_APPLE: () => `${API_ROOT}api/1/core/login/apple`,
  POST_CORE_LOGIN: () => `${API_ROOT}api/1/core/login`,
  POST_CORE_REGISTER_AND_LOGIN: () => `${API_ROOT}api/1/core/registration`,
  POST_LOGIN_MAP: () => `${API_ROOT}api/1/login/map`, //TODO:PHASE:CLEAN_UP Remove this and related code.
  POST_LOGIN: () => `${API_ROOT}api/1/login`,

  // User
  GET_REGISTRATIONS: (tournamentId: number, userId: number) =>
    `${API_ROOT}api/1/site-registration/tournament/${tournamentId}/user/${userId}/registrations`,
  GET_GGB_USER_SEARCH: `${API_ROOT}api/1/site-registration/user`,
  POST_TEMP_GGB_USER: `${API_ROOT}api/1/site-registration/user`,

  // Player
  GET_TOURNAMENT_PLAYER_PRODUCTS: (tournamentId: number, playerId: number) =>
    `${API_ROOT}api/1/site-registration/tournament/${tournamentId}/player/${playerId}/products`,
  GET_TOURNAMENT_PLAYER_SEARCH: (tournamentId: number) =>
    `${API_ROOT}api/1/site-registration/tournament/${tournamentId}/player`,
  POST_TOURNAMENT_PLAYER: (tournamentId: number) =>
    `${API_ROOT}api/1/site-registration/tournament/${tournamentId}/player`,
  PUT_TOURNAMENT_PLAYER: (tournamentId: number, playerId: number) =>
    `${API_ROOT}api/1/site-registration/tournament/${tournamentId}/player/${playerId}`,

  // Team
  POST_TOURNAMENT_TEAM: (tournamentId: number) => `${API_ROOT}api/1/site-registration/tournament/${tournamentId}/team`,
  PUT_TOURNAMENT_TEAM: (tournamentId: number, teamId: number) =>
    `${API_ROOT}api/1/site-registration/tournament/${tournamentId}/team/${teamId}`,

  // Tournament
  GET_CONTEST_LEADERBOARD: (tournamentId: number) =>
    `${API_ROOT}api/1/site/tournament/${tournamentId}/contest-leaderboard`,
  GET_LEADERBOARD_WITH_DIVISIONS: (tournamentId: number, divisionId: number) =>
    `${API_ROOT}api/1/site/tournament/${tournamentId}/division/${divisionId}/leaderboard`,
  GET_LEADERBOARD: (tournamentId: number) => `${API_ROOT}api/1/site/tournament/${tournamentId}/leaderboard`,
  GET_PLAYER_SEARCH_PROVIDER: (tournamentId: number) =>
    `${API_ROOT}api/1/site-registration/tournament/${tournamentId}/players/search/provider`,
  GET_PLAYERS: (tournamentId: number) => `${API_ROOT}api/1/site/tournament/${tournamentId}/players`,
  GET_RANKING: (rankingId: number) => `${API_ROOT}api/1/site/ranking/${rankingId}/leaderboard`,
  GET_STARTLISTS: (tournamentId: number) => `${API_ROOT}api/1/site/tournament/${tournamentId}/startlists`,
  GET_TEAMS: (tournamentId: number) => `${API_ROOT}api/1/site/tournament/${tournamentId}/teams`,
  GET_TOURNAMENT_PAYMENT: (tournamentId: number, transactionId: string) =>
    `api/1/site-registration/tournament/${tournamentId}/payment/${transactionId}`,
  GET_TOURNAMENT_PRODUCTS: (tournamentId: number) =>
    `${API_ROOT}api/1/site-registration/tournament/${tournamentId}/products`,
  GET_TOURNAMENT: (tournamentId: number) => `${API_ROOT}api/1/site/tournament/${tournamentId}`,
  POST_START_PAYMENT_PROCESS: (tournamentId: number) =>
    `${API_ROOT}api/1/site-registration/tournament/${tournamentId}/payment`,
  POST_TOURNAMENT_PAYMENT_PROCEED: (tournamentId: number, transactionId: string) =>
    `api/1/site-registration/tournament/${tournamentId}/payment/${transactionId}/proceed`,

  // Misc
  DELETE_LEAVE_TOURNAMENT: (tournamentId: number) =>
    `${API_ROOT}api/1/site-registration/tournament/${tournamentId}/registration`,
  GET_CALENDAR: (calendarId: string) => `${API_ROOT}api/1/site/calendar/${calendarId}`,
  GET_CLUBS_UHS: `${API_ROOT}api/1/site/clubs`,
  GET_COUNTRIES: `${API_ROOT}api/1/countries`,
  GET_COUNTRY_STATES: (countryId: number) => `${API_ROOT}api/1/country/${countryId}/states`,
  GET_CURRENCIES: `${API_ROOT}api/1/currencies`,
  GET_ORGANIZATION_PRODUCTS: (organizationId: number) => `${API_ROOT}api/1/organization/${organizationId}/products`,
  GET_SEARCH_CLUBS: `${API_ROOT}api/1/site/clubs/search`,
  GET_TOUR: (tourId: number) => `${API_ROOT}api/1/site/tour/${tourId}`,
  POST_VERIFY_NETS_PAYMENT: (tournamentId: number) =>
    `${API_ROOT}api/1/site/tournament/${tournamentId}/payment/nets-return`,
}

export const LOCAL_STORAGE_LANG_KEY = 'tournament_lang_token'

export const SITE_TITLE = 'Golf GameBook Tournament'
export const TITLE_DIVIDER = ' | '

const getTournamentSiteDomain = () => {
  if (process.env.NODE_ENV === 'development') {
    return 'http://localhost:3099'
  } else if (process.env.REACT_APP_ENV === 'sandbox') {
    return 'https://tournament-site.sandbox.golfgamebook.com'
  } else if (process.env.REACT_APP_ENV === 'staging') {
    return 'https://tournament-site.staging.golfgamebook.com'
  } else if (process.env.REACT_APP_ENV === 'rc') {
    return 'https://tournament-site.rc.golfgamebook.com'
  } else if (process.env.REACT_APP_ENV === 'production') {
    return 'https://tournament-site.golfgamebook.com'
  } else {
    return 'https://tournament-site.sandbox.golfgamebook.com'
  }
}

export const formTournamentSiteUrl = (id: number): string => {
  return `${getTournamentSiteDomain()}/tournament/${id}`
}

export const formTourSiteUrl = (id: number): string => {
  return `${getTournamentSiteDomain()}/tour/${id}`
}

export const getOfficeRoot = () => {
  if (process.env.NODE_ENV === 'development') {
    return 'https://office.sandbox.golfgamebook.com'
  } else if (process.env.REACT_APP_ENV === 'sandbox') {
    return 'https://office.sandbox.golfgamebook.com'
  } else if (process.env.REACT_APP_ENV === 'staging') {
    return 'https://office.staging.golfgamebook.com'
  } else if (process.env.REACT_APP_ENV === 'rc') {
    return 'https://office.rc.golfgamebook.com'
  } else if (process.env.REACT_APP_ENV === 'production') {
    return 'https://office.golfgamebook.com'
  } else {
    return 'https://office.sandbox.golfgamebook.com'
  }
}

export const getAppleLoginRedirectUrl = () => {
  if (process.env.REACT_APP_ENV === 'sandbox') {
    return 'https://tournament-site.sandbox.golfgamebook.com'
  } else if (process.env.REACT_APP_ENV === 'staging') {
    return 'https://tournament-site.staging.golfgamebook.com'
  } else if (process.env.REACT_APP_ENV === 'rc') {
    return 'https://tournament-site.rc.golfgamebook.com'
  } else if (process.env.REACT_APP_ENV === 'production') {
    return 'https://tournament-site.golfgamebook.com'
  } else {
    return 'https://tournament-site.sandbox.golfgamebook.com'
  }
}

export const getDefaultProfileImage = () => {
  const env = process.env.REACT_APP_ENV || 'sandbox'
  if (env === 'production') {
    return 'https://static.golfgamebook.com/defaults/avatar.png'
  }
  return `https://static.${env}.golfgamebook.com/defaults/avatar.png`
}
