import {
  CLOSE_REGISTER_MODAL,
  OPEN_REGISTER_MODAL,
  TOURNAMENT_ERROR,
  TOURNAMENT_REQUESTING,
  TOURNAMENT_SUCCESS,
  LEAVE_TOURNAMENT_ACTION_SUCCESS,
  SET_ACTIVE_DIVISION,
  SET_ACTIVE_DIVISION_ENDPOINT,
  TOURNAMENT_SET_SELECTED_ROUND,
  PLAYERS_SEARCH_SUCCESS,
  CLEAR_SEARCH,
  PLAYERS_SEARCH,
  PLAYERS_SEARCH_FAILURE,
} from './constants'
import {
  CloseRegisterModalAction,
  GetTournamentAction,
  GetTournamentErrorAction,
  GetTournamentSuccessAction,
  OpenRegisterModalAction,
  LeaveTournamentSuccessAction,
  SetActiveDivisionAction,
  SetActiveDivisionEndpointAction,
  TournamentSetSelectedRoundAction,
  SearchPlayersSuccessAction,
  ClearSearchAction,
  SearchPlayersFailureAction,
  SearchPlayersAction,
} from './actions'

export const initialState: TournamentState = {
  isLoading: false,
  success: false,
  error: false,
  tournamentSite: undefined,
  signUpModalOpen: false,
  cancelSuccess: false,
  activeDivision: 0,
  activeDivisionEndpoint: '',
  selectedRoundIndex: 0,
  registryPlayerSearchResult: [],
  searchLoading: false,
}

type TournamentAction =
  | GetTournamentSuccessAction
  | GetTournamentAction
  | GetTournamentErrorAction
  | OpenRegisterModalAction
  | CloseRegisterModalAction
  | LeaveTournamentSuccessAction
  | SetActiveDivisionAction
  | SetActiveDivisionEndpointAction
  | TournamentSetSelectedRoundAction
  | SearchPlayersSuccessAction
  | ClearSearchAction
  | SearchPlayersFailureAction
  | SearchPlayersAction

export const tournamentReducer = (state: TournamentState = initialState, action: TournamentAction): TournamentState => {
  switch (action.type) {
    case OPEN_REGISTER_MODAL:
      return {
        ...state,
        signUpModalOpen: true,
        cancelSuccess: false,
      }

    case CLOSE_REGISTER_MODAL:
      return {
        ...state,
        signUpModalOpen: false,
        cancelSuccess: false,
      }

    case LEAVE_TOURNAMENT_ACTION_SUCCESS:
      return {
        ...state,
        cancelSuccess: true,
      }

    case SET_ACTIVE_DIVISION:
      return {
        ...state,
        activeDivision: action.divisionId,
      }

    case SET_ACTIVE_DIVISION_ENDPOINT:
      return {
        ...state,
        activeDivisionEndpoint: action.divisionEndpoint,
      }

    case TOURNAMENT_SET_SELECTED_ROUND:
      return {
        ...state,
        selectedRoundIndex: action.roundIndex,
      }

    case TOURNAMENT_REQUESTING:
      return {
        ...initialState,
        isLoading: true,
      }

    case TOURNAMENT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        success: true,
        error: false,
        tournamentSite: action.payload,
      }
    }

    case PLAYERS_SEARCH: {
      return {
        ...state,
        searchLoading: true,
      }
    }

    case PLAYERS_SEARCH_SUCCESS: {
      return {
        ...state,
        registryPlayerSearchResult: action.players,
        searchLoading: false,
      }
    }

    case PLAYERS_SEARCH_FAILURE: {
      return {
        ...state,
        searchLoading: false,
      }
    }

    case TOURNAMENT_ERROR: {
      return {
        ...initialState,
        error: true,
      }
    }

    case CLEAR_SEARCH: {
      return {
        ...state,
        registryPlayerSearchResult: [],
        searchLoading: false,
      }
    }

    default:
      return state
  }
}
