import {
  AUTH_LOGIN_FAILURE,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT_SUCCESS,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_REGISTER_AND_LOGIN,
  AUTH_LOGIN_APPLE,
  AUTH_LOGIN_APPLE_FAILURE,
} from './constants'
import get from 'lodash/get'

export const initialState: AuthenticationState = {
  loading: false,
  appleLoading: false,
  error: undefined,
  appleError: undefined,
  user: undefined,
}

type AuthenticationActions =
  | LoginAction
  | AppleLoginAction
  | LogOutAction
  | LoginSuccessAction
  | LogOutSuccessAction
  | LoginFailureAction
  | AppleLoginFailureAction
  | RegisterAndLoginAction

const authenticationReducer = (state: AuthenticationState = initialState, action: AuthenticationActions) => {
  switch (action.type) {
    case AUTH_LOGIN:
      return {
        ...state,
        appleError: undefined,
        loading: true,
      }
    case AUTH_REGISTER_AND_LOGIN:
      return {
        ...state,
        appleError: undefined,
        loading: true,
      }
    case AUTH_LOGIN_APPLE:
      return {
        ...state,
        appleError: undefined,
        appleLoading: true,
      }
    case AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        appleLoading: false,
        error: undefined,
        appleError: undefined,
        user: {
          ...action.payload,
          hcp: get(action, 'payload.hcp', 0),
        },
      }
    case AUTH_LOGIN_FAILURE:
      return {
        ...state,
        loggedIn: false,
        loading: false,
        error: action.error,
      }
    case AUTH_LOGIN_APPLE_FAILURE:
      return {
        ...state,
        loggedIn: false,
        appleLoading: false,
        appleError: action.error,
      }
    case AUTH_LOGOUT_SUCCESS:
      return {
        ...state,
        name: null,
        email: null,
        loggedIn: false,
        loading: false,
        error: undefined,
      }
    case AUTH_LOGOUT:
      return initialState
    default:
      return state
  }
}

export { authenticationReducer }
