import axios from 'axios'
import { APIRoute } from '../../config'
import { all, select, put, takeEvery, delay } from 'redux-saga/effects'
import { getTournamentId } from '../../store/tournament/selectors'
import {
  START_PAYMENT_PROCESS,
  START_PAYMENT_PROCESS_SUCCESS,
  START_PAYMENT_PROCESS_FAILURE,
  PAYMENT_PROCESS,
  FETCH_TOURNAMENT_PRODUCTS_FAILURE,
  FETCH_TOURNAMENT_PRODUCTS,
  FETCH_TOURNAMENT_PRODUCTS_SUCCESS,
  FETCH_BOUGHT_PRODUCTS,
  FETCH_BOUGHT_PRODUCTS_SUCCESS,
  FETCH_BOUGHT_PRODUCTS_FAILURE,
  VERIFY_NETS_PAYMENT,
  VERIFY_NETS_PAYMENT_SUCCESS,
  VERIFY_NETS_PAYMENT_FAILURE,
  FETCH_CURRENCIES,
  FETCH_CURRENCIES_FAILURE,
  FETCH_CURRENCIES_SUCCESS,
} from './constants'
import {
  VerifyNetsPaymentAction,
  StartPaymentProcessAction,
  FetchBoughtAction,
  FetchTournamentProductsAction,
} from './actions'
import { TOURNAMENT_LOAD_PLAYERS, TOURNAMENT_TEAMS } from '../tournamentPlayers/constants'

function* loadPlayersAndTeams() {
  const tournamentId = yield select(getTournamentId)
  yield put({
    type: TOURNAMENT_LOAD_PLAYERS,
    id: tournamentId,
  })
  yield put({
    type: TOURNAMENT_TEAMS,
    tournamentId,
  })
}

function* doFetchTournamentProducts({ payload, onComplete }: FetchTournamentProductsAction) {
  try {
    const { authToken } = payload
    const tournamentId = yield select(getTournamentId)

    const res = yield axios.get(APIRoute.GET_TOURNAMENT_PRODUCTS(tournamentId), {
      headers: { Authorization: `Bearer ${authToken}` },
    })

    const tournamentProducts: TournamentProducts = res.data.siteTournamentProducts
    yield put({
      type: FETCH_TOURNAMENT_PRODUCTS_SUCCESS,
      tournamentProducts,
      productsFetched: true,
    })

    onComplete(true)
  } catch (error: any) {
    yield put({
      type: FETCH_TOURNAMENT_PRODUCTS_FAILURE,
      error,
    })
    onComplete(false)
  }
}

function* doStartPaymentProcess({ payload, onSuccess }: StartPaymentProcessAction) {
  try {
    const tournamentId = yield select(getTournamentId)
    const { authToken, language, tournamentProducts } = payload
    const res = yield axios.post(
      APIRoute.POST_START_PAYMENT_PROCESS(tournamentId),
      {
        tournamentProducts,
        language,
      },
      { headers: { Authorization: `Bearer ${authToken}` } },
    )

    yield put({
      type: START_PAYMENT_PROCESS_SUCCESS,
    })

    onSuccess(res.data.terminalURL)
  } catch (error: any) {
    yield put({
      type: START_PAYMENT_PROCESS_FAILURE,
      error,
    })
  }
}

function* doVerifyNetsPayment({ payload }: VerifyNetsPaymentAction) {
  try {
    const tournamentId = yield select(getTournamentId)
    yield axios.post(APIRoute.POST_VERIFY_NETS_PAYMENT(tournamentId), payload)
    yield put({
      type: VERIFY_NETS_PAYMENT_SUCCESS,
    })
    yield loadPlayersAndTeams()
  } catch (error: any) {
    const retryCount = payload.retryCount || 0
    if (retryCount < 20) {
      yield delay(5000)
      yield put({
        type: VERIFY_NETS_PAYMENT,
        payload: { ...payload, retryCount: retryCount + 1 },
      })
    } else {
      yield put({
        type: VERIFY_NETS_PAYMENT_FAILURE,
        error,
      })
    }
  }
}

function* doGetBoughtProducts({ payload }: FetchBoughtAction) {
  try {
    const { authToken, playerId } = payload
    const tournamentId = yield select(getTournamentId)
    const res = yield axios.get(APIRoute.GET_TOURNAMENT_PLAYER_PRODUCTS(tournamentId, playerId), {
      headers: { Authorization: `Bearer ${authToken}` },
    })

    yield put({
      type: FETCH_BOUGHT_PRODUCTS_SUCCESS,
      purchasedTournamentProductIds: res.data.purchasedTournamentProductIds,
    })
  } catch (error: any) {
    yield put({
      type: FETCH_BOUGHT_PRODUCTS_FAILURE,
      error,
    })
  }
}

function* doFetchCurrencies() {
  try {
    const res = yield axios.get(APIRoute.GET_CURRENCIES)

    yield put({
      type: FETCH_CURRENCIES_SUCCESS,
      currencies: res.data,
    })
  } catch (error: any) {
    yield put({
      type: FETCH_CURRENCIES_FAILURE,
      error,
    })
  }
}

export function* watchPayment() {
  yield all([takeEvery(FETCH_TOURNAMENT_PRODUCTS, doFetchTournamentProducts)])
  yield all([takeEvery(START_PAYMENT_PROCESS, doStartPaymentProcess)])
  yield all([takeEvery(PAYMENT_PROCESS, doStartPaymentProcess)])
  yield all([takeEvery(VERIFY_NETS_PAYMENT, doVerifyNetsPayment)])
  yield all([takeEvery(FETCH_BOUGHT_PRODUCTS, doGetBoughtProducts)])
  yield all([takeEvery(FETCH_CURRENCIES, doFetchCurrencies)])
}
