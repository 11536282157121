import { AUTH_LOGIN, AUTH_LOGIN_SUCCESS, AUTH_LOGOUT, AUTH_REGISTER_AND_LOGIN, AUTH_LOGIN_APPLE } from './constants'

export const login = (loginData: LoginPayload) => {
  return {
    type: AUTH_LOGIN,
    payload: loginData,
  }
}

export const loginApple = (loginData: AppleLoginPayload) => {
  return {
    type: AUTH_LOGIN_APPLE,
    payload: loginData,
  }
}

export const loginSuccess = (payload: LoginReturnPayload) => {
  return {
    type: AUTH_LOGIN_SUCCESS,
    payload,
  }
}

export const logout = (): LogOutAction => ({
  type: AUTH_LOGOUT,
})

export const registerAndLogin = (registerData: RegisterPayload) => {
  return {
    type: AUTH_REGISTER_AND_LOGIN,
    payload: registerData,
  }
}
