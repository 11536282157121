import React from 'react'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import createStyles from '@mui/styles/createStyles'
import Button, { ButtonProps } from '@mui/material/Button'
import { Theme } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: `12px 34px`,
      borderRadius: 6,
      boxShadow: 'none',
      minWidth: 150,
      height: 50,
      fontWeight: 500,
      borderWidth: 2,
      '&:hover': {
        borderWidth: 2,
      },
      [theme.breakpoints.down('md')]: {
        padding: 0,
      },
    },
    label: {
      fontSize: theme.typography.pxToRem(15),
      textAlign: 'center',
      lineHeight: 1,
    },
    outlined: {
      borderColor: theme.palette.primary.main,
    },
  })

interface Props extends WithStyles<typeof styles> {
  loading?: boolean
  disabled?: boolean
  color?: ButtonProps['color']
  className?: string
  children: React.ReactNode
  buttonProps?: ButtonProps
  variant?: 'text' | 'outlined' | 'contained'
}

const TourButton = ({
  loading = false,
  disabled = false,
  color = 'primary',
  classes,
  className,
  children,
  buttonProps = {},
  variant,
}: Props) => {
  const getClassName = (): string => {
    if (className) return className
    if (variant === 'outlined') return classes.outlined
    return ''
  }

  return (
    <Button
      disabled={loading || disabled}
      classes={classes}
      variant={variant ? variant : 'contained'}
      color={color}
      {...buttonProps}
      className={getClassName()}
    >
      {children}
      {loading && (
        <CircularProgress
          size={24}
          style={{
            position: 'absolute',
            right: 12,
            top: '50%',
            marginTop: -12,
          }}
        />
      )}
    </Button>
  )
}

export default withStyles(styles)(TourButton)
