export const AUTH_LOGIN = 'AUTH_LOGIN'
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS'
export const AUTH_LOGIN_FAILURE = 'AUTH_LOGIN_FAILURE'
export const AUTH_REGISTER_AND_LOGIN = 'AUTH_REGISTER_AND_LOGIN'

export const AUTH_LOGIN_APPLE = 'AUTH_LOGIN_APPLE'
export const AUTH_LOGIN_APPLE_FAILURE = 'AUTH_LOGIN_APPLE_FAILURE'

export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS'

export const PLAYER_JOIN_TOURNAMENT_ACTION = 'PLAYER_JOIN_TOURNAMENT_ACTION'
export const TEAM_JOIN_TOURNAMENT_ACTION = 'TEAM_JOIN_TOURNAMENT_ACTION'

export const EDIT_REGISTRATION_ACTION = 'EDIT_REGISTRATION_ACTION'

export const LOGIN_MAP = 'LOGIN_MAP'
export const LOGIN_MAP_SUCCESS = 'LOGIN_MAP_SUCCESS'
export const LOGIN_MAP_FAILURE = 'LOGIN_MAP_FAILURE'

export const UPDATE_PLAYER_DIVISION = 'UPDATE_PLAYER_DIVISION'
