import { PaymentProcessDialog } from '../dialogs/PaymentProcessDialog'
import { useSelector } from 'react-redux'
import { EntryMethod } from '@app/utils/enums'
import { Suspense, lazy } from 'react'
import { RootState } from '@app/store'

/**
 * ModalProvider component
 * Lazy loads the modals and shows correct modal based on redux state
 */

const LazyTeamSignupModal = lazy(() => import('./TeamSignupModal'))
const LazySignupModal = lazy(() => import('./SignupModal'))
const LazyLoginModal = lazy(() => import('./LoginModal'))
const LazyRegisterModal = lazy(() => import('./RegisterModal'))
const LazyRegistrationSuccessModal = lazy(() => import('./RegistrationSuccessModal'))
const LazyCancelSuccessModal = lazy(() => import('./CancelSuccessModal'))
const LazySignupSuccessModal = lazy(() => import('./SignupSuccessModal'))

export default function ModalProvider() {
  const tournamentSite = useSelector((state: RootState) => state.tournamentReducer.tournamentSite)
  const { user } = useSelector((state: RootState) => state.authenticationReducer)
  const {
    loginModalOpen,
    registerModalOpen,
    signupModalOpen,
    registrationSuccessModalOpen,
    signupCancelledModalOpen,
    signupSuccessModalOpen,
  } = useSelector((state: RootState) => state.signupReducer)

  if (!tournamentSite) {
    return null
  }

  const isTeamFormat = tournamentSite.tournament.isTeamFormat && tournamentSite.entryMethod === EntryMethod.TEAM

  return (
    <Suspense>
      {user && isTeamFormat && <LazyTeamSignupModal open={signupModalOpen} />}
      {user && !isTeamFormat && <LazySignupModal open={signupModalOpen} />}
      <LazyRegistrationSuccessModal open={registrationSuccessModalOpen} />
      <LazyCancelSuccessModal open={signupCancelledModalOpen} />
      <LazyLoginModal open={loginModalOpen} />
      <LazyRegisterModal open={registerModalOpen} />
      <LazySignupSuccessModal open={signupSuccessModalOpen} />
      <PaymentProcessDialog />
    </Suspense>
  )
}
