import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DialogContent, Button, Typography, Grid, CircularProgress, Stack } from '@mui/material'
import { setNetsPaymentAsFailed, verifyNetsPayment } from '@app/store/payments/actions'
import { FormattedMessage } from 'react-intl'
import { PaymentResponseCode, getPaymentReturnUrl } from '@app/utils/paymentUtils'
import DialogBase from '@app/components/dialogs/DialogBase'
import { usePaymentState } from '@app/hooks'
import { useLanguage } from '@app/hooks/useLanguage'
import SignupSuccessModal from './SignupSuccessModal'

export const PaymentProcessDialog = () => {
  const dispatch = useDispatch()
  const payments = useSelector((store: StoreState) => store.paymentReducer)
  const tournamentSite = useSelector((store: StoreState) => store.tournamentReducer.tournamentSite)
  const tournamentId = tournamentSite?.tournament?.id
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false)
  const { transactionId, responseCode } = usePaymentState()
  const { languageCode } = useLanguage()

  useEffect(() => {
    if (transactionId && responseCode === PaymentResponseCode.OK && tournamentId > 0) {
      dispatch(verifyNetsPayment({ transactionId, lang: languageCode }))
      setDialogOpen(true)
    }
  }, [transactionId, responseCode, tournamentId])

  useEffect(() => {
    if (transactionId && responseCode !== PaymentResponseCode.OK) {
      dispatch(setNetsPaymentAsFailed())
      setDialogOpen(true)
    }
  }, [transactionId, responseCode])

  const handleCloseOnClick = () => {
    // Remove payment-return and params from url
    const url = getPaymentReturnUrl(tournamentSite.tournament.id)
    window.history.pushState('', '', url)

    setDialogOpen(false)
    sessionStorage.clear()
  }

  const renderTitle = () => {
    if (payments.loading) {
      return <FormattedMessage id="payments.verifyingPaymentTitle" />
    } else {
      return <FormattedMessage id="strings.error" />
    }
  }

  if (!tournamentSite) {
    return null
  }

  // Success: Use new modal
  if (!payments.error && !payments.loading) {
    return <SignupSuccessModal open={isDialogOpen} />
  }

  // Loading/error: Use old modal
  return (
    <DialogBase
      title={<Typography variant="h2">{renderTitle()}</Typography>}
      open={isDialogOpen}
      onClose={payments.loading ? undefined : handleCloseOnClick}
      fullWidth
      maxWidth={'sm'}
    >
      <DialogContent style={{ marginBottom: 20, marginTop: 20 }}>
        {payments.loading ? (
          <Stack direction="column" justifyContent="center" alignItems="center" spacing={3}>
            <CircularProgress size={64} thickness={2} />
          </Stack>
        ) : (
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <FormattedMessage id="payments.paymentProcessError" />
            </Grid>
            <Grid item>
              <Button color="primary" variant="contained" onClick={handleCloseOnClick}>
                <FormattedMessage id="buttons.close" />
              </Button>
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </DialogBase>
  )
}
