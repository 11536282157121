/* eslint-disable max-len */

export const LockIcon = ({ style = {}, width = '100%', className = '', height = '100%', viewBox = '0 0 150 150' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
    className={className}
    style={style}
    fill="none"
  >
    <g clipPath="url(#clip0_4545_7392)">
      <circle
        cx="75.7353"
        cy="75.7338"
        r="30.1103"
        transform="rotate(128.544 75.7353 75.7338)"
        stroke="url(#paint0_linear_4545_7392)"
        strokeOpacity="0.15"
        strokeWidth="17"
      />
      <circle
        cx="75.7346"
        cy="75.7335"
        r="47.0841"
        transform="rotate(-150.542 75.7346 75.7335)"
        stroke="url(#paint1_linear_4545_7392)"
        strokeOpacity="0.13"
        strokeWidth="17"
      />
      <path
        d="M75.9597 47.043C84.0828 47.043 90.6189 53.0799 90.6189 60.533V64.3663C95.1781 65.7104 98.5 69.6083 98.5 74.264V86.6062C98.5 92.3699 93.4405 97.043 87.2029 97.043H64.7998C58.5595 97.043 53.5 92.3699 53.5 86.6062V74.264C53.5 69.6083 56.8246 65.7104 61.3811 64.3663V60.533C61.4079 53.0799 67.9441 47.043 75.9597 47.043ZM75.9866 75.5037C74.6955 75.5037 73.6464 76.4726 73.6464 77.665V83.1803C73.6464 84.3976 74.6955 85.3665 75.9866 85.3665C77.3045 85.3665 78.3536 84.3976 78.3536 83.1803V77.665C78.3536 76.4726 77.3045 75.5037 75.9866 75.5037ZM76.0134 51.3906C70.5532 51.3906 66.1151 55.4649 66.0882 60.4833V63.8272H85.9118V60.533C85.9118 55.4898 81.4737 51.3906 76.0134 51.3906Z"
        fill="#098F50"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_4545_7392"
        x1="75.7353"
        y1="37.1235"
        x2="75.7353"
        y2="114.344"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1AA85D" />
        <stop offset="1" stopColor="#1AA85D" stopOpacity="0.4" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4545_7392"
        x1="117.65"
        y1="22.4274"
        x2="75.7346"
        y2="131.318"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1AA85D" stopOpacity="0" />
        <stop offset="1" stopColor="#1AA85D" />
      </linearGradient>
      <clipPath id="clip0_4545_7392">
        <rect width="150" height="150" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
