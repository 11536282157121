import { all, takeEvery } from 'redux-saga/effects'
import { LEAVE_TOURNAMENT_ACTION, PLAYERS_SEARCH, TOURNAMENT_REQUESTING } from './constants'
import getTournamentFlow from './flows/get-tournament-flow'
import {
  PLAYER_JOIN_TOURNAMENT_ACTION,
  EDIT_REGISTRATION_ACTION,
  TEAM_JOIN_TOURNAMENT_ACTION,
} from '../authentication/constants'
import { playerJoinTournamentFlow, teamJoinTournamentFlow } from './flows/join-tournament-flow'
import leaveTournamentFlow from './flows/leave-tournament-flow'
import editRegistrationFlow from './flows/edit-registration-flow'
import searchPlayersFlow from './flows/search-players-flow'

export function* watchTournament() {
  yield all([
    takeEvery(TOURNAMENT_REQUESTING, getTournamentFlow),
    takeEvery(PLAYER_JOIN_TOURNAMENT_ACTION, playerJoinTournamentFlow),
    takeEvery(TEAM_JOIN_TOURNAMENT_ACTION, teamJoinTournamentFlow),
    takeEvery(EDIT_REGISTRATION_ACTION, editRegistrationFlow),
    takeEvery(LEAVE_TOURNAMENT_ACTION, leaveTournamentFlow),
    takeEvery(PLAYERS_SEARCH, searchPlayersFlow),
  ])
}
