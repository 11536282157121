import { CSSProperties } from 'react'
import { FormattedMessage } from 'react-intl'
import TourButton from './TourButton'
import { connect, useDispatch } from 'react-redux'
import get from 'lodash/get'
import { isRegistrationOpen, isCancelRegistrationOpen } from '../../store/tournament/selectors'
import { openSignupProcess } from '@app/store/signup/signupSlice'

interface StateProps {
  onlineEntriesEnabled: boolean
  style?: CSSProperties
  auth: AuthenticationState // todo: catch when the user is already registerd and hide btn
  isRegistrationOpen: boolean
  isCancelRegistrationOpen: boolean
}

type Props = StateProps

const RegisterToTourButton = ({ onlineEntriesEnabled, isRegistrationOpen, isCancelRegistrationOpen, style }: Props) => {
  // Don't show if entries are disabled
  // Don't show if entries are not open or entries are closed
  if (!onlineEntriesEnabled) return null
  if (!isRegistrationOpen && !isCancelRegistrationOpen) return null

  const dispatch = useDispatch()

  return (
    <TourButton
      buttonProps={{ onClick: () => dispatch(openSignupProcess()), disabled: false, style: style }}
      variant={'contained'}
    >
      <FormattedMessage id={'buttons.signUp'} />
    </TourButton>
  )
}

export default connect((state: StoreState) => ({
  auth: state.authenticationReducer,
  onlineEntriesEnabled: get(state, 'tournamentReducer.tournamentSite.onlineEntriesEnabled', false),
  isRegistrationOpen: isRegistrationOpen(state),
  isCancelRegistrationOpen: isCancelRegistrationOpen(state),
}))(RegisterToTourButton)
