import { SuccessIcon } from '@app/assets/images/icons'
import { Dialog, Theme, Typography } from '@mui/material'
import { FormattedMessageWrapper } from '../ui/FormattedMessageWrapper'
import TourButton from '../ui/TourButton'
import { FormattedMessage } from 'react-intl'
import {
  AppAdDownloadBackgroundMob,
  AppAdDownloadBackgroundWeb,
  AppStore,
  GGBAppQR,
  GGBAppQR2x,
  GooglePlay,
  SignUpSuccessWeb,
  SignUpSuccessWeb2x,
} from '@app/assets/images'
import { makeStyles } from '@mui/styles'
import { useEffect, useState } from 'react'
import CancelIcon from '@mui/icons-material/Cancel'
import { debounce } from 'lodash'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '@app/store'
import { logout } from '@app/store/authentication/actions'
import { getTournamentTeams, loadTournamentPlayers } from '@app/store/tournamentPlayers/actions'

const useStyles = makeStyles((theme: Theme) => ({
  signUpSuccessModal: {
    '& .MuiDialog-paper': {
      width: '100%',
      maxWidth: 757,
      borderRadius: 12,
      margin: 14,
      textAlign: 'center',
      position: 'relative',
    },

    '& ul': {
      textAlign: 'left',
      paddingLeft: 18,
    },
  },
  cancelIcon: {
    position: 'absolute',
    top: 20,
    right: 20,
    cursor: 'pointer',
    color: '#555555',
  },
  contentWrapper: {
    maxWidth: 597,
    margin: '0 auto',
    padding: '30px 20px 50px',
  },
  dialogTitle: {
    fontSize: 20,

    [theme.breakpoints.up('md')]: {
      fontSize: 30,
    },
  },
  signUpSuccessText: {
    marginTop: 16,
    fontSize: 16,
    lineHeight: 1.5,

    [theme.breakpoints.up('md')]: {
      fontSize: 18,
    },
  },
  signUpSuccessImage: {
    width: '100%',
    marginBottom: 24,

    [theme.breakpoints.up('md')]: {
      marginBottom: 60,
    },
  },
  wideButton: {
    width: '100%',
    maxWidth: 316,
    marginBottom: 12,
    fontSize: 16,
  },
  appAdTitle: {
    fontSize: 16,
    marginBottom: 15,
    color: '#fff',
    lineHeight: 1.35,
    fontWeight: 800,

    [theme.breakpoints.up('md')]: {
      fontSize: 24,
    },
  },
  appAdScanAndDownload: {
    backgroundColor: '#000',
    backgroundImage: `url(${AppAdDownloadBackgroundMob})`,
    backgroundSize: 'cover',
    padding: '24px 16px 20px',
    textAlign: 'center',
    display: 'flex',

    [theme.breakpoints.up('md')]: {
      backgroundImage: `url(${AppAdDownloadBackgroundWeb})`,
      padding: '64px 96px 45px',
      textAlign: 'left',
      gap: 50,
    },
  },
  QRCodeImg: {
    width: '100%',
    maxWidth: 130,
    height: 130,

    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  appLinks: {
    display: 'flex',
    gap: '12px',
    justifyContent: 'center',

    [theme.breakpoints.up('md')]: {
      gap: '24px',
      justifyContent: 'flex-start',
    },

    '& img': {
      height: 31,

      [theme.breakpoints.up('md')]: {
        height: 40,
      },
    },
  },
}))

export default function SignupSuccessModal({ open }: { open: boolean }) {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const { onReserveList } = useSelector((state: RootState) => state.signupReducer)
  const { tournamentSite } = useSelector((state: RootState) => state.tournamentReducer)
  const [width, setWidth] = useState<number>(window.innerWidth)

  const handleWindowSizeChange = debounce(() => {
    setWidth(window.innerWidth)
  }, 100)

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const handleClose = () => {
    dispatch(logout())
    dispatch(loadTournamentPlayers(tournamentSite.tournament.id))
    dispatch(getTournamentTeams(tournamentSite.tournament.id))

    window.location.href = `/tournament/${tournamentSite.tournament.id}`
  }

  const isMobile = width <= 900

  return (
    <Dialog className={classes.signUpSuccessModal} open={open}>
      <CancelIcon className={classes.cancelIcon} onClick={handleClose} />
      <div className={classes.contentWrapper}>
        <SuccessIcon style={{ width: 85, height: 85, marginBottom: 10 }} />
        <Typography variant="h2" component="h2" className={classes.dialogTitle}>
          {onReserveList ? (
            <FormattedMessageWrapper id="strings.signUpSuccessReserveTitle" />
          ) : (
            <FormattedMessage id="strings.signUpSuccessTitle" />
          )}
        </Typography>
        <div className={classes.signUpSuccessText}>
          <FormattedMessageWrapper id="strings.signUpSuccessText" />
        </div>
        <img
          alt={'Golf Gamebook App'}
          src={SignUpSuccessWeb}
          srcSet={`${SignUpSuccessWeb2x} 2x`}
          className={classes.signUpSuccessImage}
        />
        {isMobile ? (
          <>
            <TourButton
              className={classes.wideButton}
              buttonProps={{
                href: 'https://golfgamebook.go.link/?adj_t=19yifrvf',
                onClick: handleClose,
              }}
            >
              <FormattedMessage id="buttons.openApp" />
            </TourButton>
            <TourButton
              className={classes.wideButton}
              color="secondary"
              buttonProps={{
                onClick: handleClose,
              }}
            >
              <FormattedMessage id="buttons.close" />
            </TourButton>
          </>
        ) : (
          <TourButton className={classes.wideButton} buttonProps={{ onClick: handleClose }}>
            <FormattedMessage id="buttons.close" />
          </TourButton>
        )}
      </div>
      <div className={classes.appAdScanAndDownload}>
        <img
          className={classes.QRCodeImg}
          alt={'Scan the QR code to download the Golf Gamebook App'}
          src={GGBAppQR}
          srcSet={`${GGBAppQR2x} 2x`}
        />
        <div style={{ width: '100%' }}>
          <Typography variant="h2PublicSans" className={classes.appAdTitle}>
            {isMobile ? (
              <FormattedMessage id="strings.appAdDownload" />
            ) : (
              <FormattedMessage id="strings.appAdScanAndDownload" />
            )}
          </Typography>
          <div className={classes.appLinks}>
            <a
              // eslint-disable-next-line max-len
              href="https://app.adjust.com/88ueiz6?redirect=http%3A%2F%2Fitunes.apple.com/us/app/golf-gamebook-best-golf-app/id409307935?mt=8"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={AppStore} alt="Download Golf GameBook on AppStore" />
            </a>
            <a
              // eslint-disable-next-line max-len
              href="https://app.adjust.com/88ueiz6?redirect=http%3A%2F%2Fplay.google.com/store/apps/details?id=com.freedropinnovations.gamebookInter"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={GooglePlay} alt="Get Golf GameBook on Google Play" />
            </a>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
