import axios from 'axios'
import { put, select, all } from '@redux-saga/core/effects'
import { getTournamentId } from '../../tournament/selectors'
import { APIRoute } from '../../../config'
import { find, toInteger } from 'lodash'
import { FETCH_REGISTRATIONS_FAILURE, FETCH_REGISTRATIONS_SUCCESS } from '../constants'
import {
  registrationTeamFetched,
  teamMemberSkeleton,
  userFieldUpdated,
  userQuestionAnswerUpdated,
} from '@app/store/signup/signupSlice'

export default function* doFetchRegistrations({ payload }: LoginSuccessAction) {
  try {
    const { userId, authToken } = payload

    const tournamentId = yield select(getTournamentId)

    const { data } = yield axios.get(APIRoute.GET_REGISTRATIONS(tournamentId, toInteger(userId)), {
      headers: { Authorization: `Bearer ${authToken}` },
    })
    const { registrations } = data
    const registration: UserRegistration = find(registrations, { tournamentId: tournamentId })

    if (registration) {
      yield put(userFieldUpdated({ fieldName: 'hcp', value: registration.handicap }))
      yield put(userFieldUpdated({ fieldName: 'teebox', value: registration.teebox }))
      yield put(userFieldUpdated({ fieldName: 'divisionId', value: registration.divisionId }))

      yield all(registration.customQuestions.map((q) => put(userQuestionAnswerUpdated({ id: q.id, answer: q.answer }))))

      if (registration.team?.id) {
        // Add the whole team to signupSlice
        const team = {
          id: registration.team.id,
          name: registration.team.name,
          players: [],
          divisionId: registration.divisionId,
        }

        const teamWithClubNames = registration.team.players.map((player) => ({
          ...teamMemberSkeleton,
          ...player,
          clubName: player?.club?.name,
        }))

        team.players = teamWithClubNames

        yield put(registrationTeamFetched({ team }))
      }
    }

    yield put({
      type: FETCH_REGISTRATIONS_SUCCESS,
      registrations,
      registration,
    })
  } catch (error) {
    console.log(`User registration ${payload.userId} requested - ${error}`)
    yield put({
      type: FETCH_REGISTRATIONS_FAILURE,
      error: error.toString(),
    })
  }
}
