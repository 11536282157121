import axios from 'axios'
import { APIRoute } from '../../../config'
import { EditRegistrationAction } from '../actions'

function* editRegistrationFlow({
  tournamentId,
  playerId,
  authToken,
  hcp,
  customQuestions,
  team,
  teamName,
  teebox,
  divisionId,
  onComplete,
}: EditRegistrationAction) {
  try {
    yield axios.put(
      APIRoute.PUT_TOURNAMENT_PLAYER(tournamentId, playerId),
      {
        hcp,
        customQuestions,
        team,
        teamName,
        teebox,
        divisionId,
      },
      { headers: { Authorization: `Bearer ${authToken}` } },
    )
    onComplete(true)
  } catch (error) {
    onComplete(false, false, error)
  }
}

export default editRegistrationFlow
